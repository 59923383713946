<template>
  <div class="NavClass" :style="listheight">
    <div class="block" v-for="(item,index) in Data" :key="index" @click="ToPush(item)">
      <div class="top"></div>
      <div class="bg">
        <img :src="item.CoverImage" alt="">
      </div>
      <div class="title">{{ item.Title }}</div>
      <div class="brief">{{ item.Slogan }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Data: {
      type: Array
    }
  },
  data() {
    return {
      listheight: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ToPush(value) {
      this.$router.push(`/Object?id=${value.ObjectID}`)
    },
    init() {
      let listheight = document.documentElement.clientHeight - 110 - 70
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }
}
</script>

<style scoped lang="scss">
.NavClass {
  width: 100%;
  overflow-y: auto;

  .block {
    width: 90%;
    height: 260px;
    background-color: #212330;
    border-radius: 12px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 15px;

    .top {
      width: 100%;
      height: 20px;
    }

    .bg {
      width: 90%;
      height: 150px;
      margin: 0 auto;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      color: #FFFFFF;
      font-weight: bold;
      text-indent: 2rem;
      margin-bottom: 10px;
    }

    .brief {
      background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: bold;
      font-size: 14px;
      text-indent: 2rem;
    }
  }
}
</style>