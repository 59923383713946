<template>
  <div class="Object">
    <div class="navBlock"></div>
    <NavClass :TClass="TClass"></NavClass>
    <ObjectData :Data="Data"></ObjectData>
  </div>
</template>

<script>
import NavClass from './Com/NavClass.vue'
import ObjectData from './Com/ObjectData.vue'
import {PartObjectData, FindInterestList} from "@/api/Object";

export default {
  data() {
    return {
      TClass: '关注的项目',
      Data: []
    }
  },
  components: {
    NavClass,
    ObjectData
  },
  mounted() {
    this.getdata()
  },
  methods: {
    switchClass(value) {
      this.TClass = value
      this.getdata()
    },
    getdata() {
      let TClass = this.TClass
      if (TClass == '关注的项目') {
        this.Interest()
      } else {
        this.TolData()
      }
    },
    Interest() {
      let info = this.$store.state.user.info
      let User_ID = info.id

      FindInterestList({User_ID}).then(res => {
        let data = res.payload
        this.Data = data
      })
    },
    TolData() {
      let info = this.$store.state.user.info
      let id = info.id

      PartObjectData({id}).then(res => {
        let data = res.payload
        this.Data = data
      })

    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 100%;
  background-color: #17191f;

  .navBlock {
    width: 100%;
    height: 60px;
  }
}
</style>
