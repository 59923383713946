import request from '../utils/request';

//获取项目基本数据
export const PartObjectData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/PartObject',
        method: 'get',
        params: query
    });
};

//获取项目数据(ID)
export const FindInfo = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/FindInfo',
        method: 'get',
        params: query
    });
};


//添加用户关注项目
export const FindInterestList = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/FindInterestList',
        method: 'get',
        params: query
    });
};


//添加用户关注项目
export const AddInterest = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/AddInterest',
        method: 'post',
        data: query
    });
};

//取消用户关注项目
export const CloseInterest = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/CloseInterest',
        method: 'post',
        data: query
    });
};

//获取项目发展日程
export const FindBrief = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/FindBrief',
        method: 'get',
        params: query
    });
};


//获取项目委员会
export const FindCommittee = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/FindCommittee',
        method: 'get',
        params: query
    });
};


//获取项目委员会
export const FindNew = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/FindNew',
        method: 'get',
        params: query
    });
};


//添加用户留言
export const AddLeaveMessage = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Object/AddLeaveMessage',
        method: 'post',
        data: query
    });
};
