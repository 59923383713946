<template>
  <div class="NavToPush">
    <div class="block" v-bind:class="{blocks:TClass=='关注的项目'}"  @click="switchClass('关注的项目')">
      关注的项目
      <div class="link" v-bind:class="{link_1:TClass=='关注的项目'}"></div>
    </div>
    <div class="block" v-bind:class="{blocks:TClass=='全部项目'}"  @click="switchClass('全部项目')">
      全部项目
      <div class="link" v-bind:class="{link_1:TClass=='全部项目'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TClass: {
      type: String
    }
  },
  methods: {
    switchClass(value) {
      if (value == this.TClass) {
        return
      }
      this.$parent.switchClass(value)
    }
  }
}
</script>

<style scoped lang="scss">
.NavToPush {
  width: 90%;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;

  .block {
    width: 50%;
    height: 100%;
    text-align: center;
    position: relative;
    background:#57595d;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: 14px;

    .link {
      width: 100%;
      height: 2px;
      background: #57595d;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .link_1 {
      background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    }
  }

  .blocks{
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    -webkit-background-clip: text;
  }
}
</style>